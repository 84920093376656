<template>
    <Page
        title="Completing QB Company"
        icon="cloud_download"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
    >
        {{ errorMessage }}

        <v-layout slot="actions"> <btn color="success" @click="goBack" small>Back</btn> </v-layout>
    </Page>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            errorMessage: '',
            isShowError: false,
            isShowLoading: false,
            loadingMessage: '',
        };
    },
    methods: {
        ...mapActions(['completeQBCompany']),
        goBack() {
            this.$router.push('connectqb');
        },
    },
    async created() {
        this.loadingMessage = 'Configuring company';
        this.isShowLoading = true;

        console.log(this.$route.query);
        const { code, error } = this.$route.query;

        if (code) {
            console.log('route - ', this.$route.fullPath);
            const companyId = await this.completeQBCompany({
                path: this.$route.fullPath,
            });
            this.isShowLoading = false;
            this.$router.push(`company/sync/${companyId}`);
        }
        if (error) {
            this.errorMessage = `Quickbooks company access not granted. (${error})`;
        }
        this.isShowLoading = false;
    },
};
</script>
